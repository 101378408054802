import React from 'react'
import { BsTwitter, BsInstagram, BsLinkedin, BsGithub } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';

const SocialMedia = () => {
  return (
    <div className="app__social">
        <div>
          <a href='https://twitter.com/gabetierra_tech' target='_blank' rel='noferrer'>
            <BsTwitter />
          </a>
        </div>
        <div>
          <a href="https://www.linkedin.com/in/gabriel-tierra-6a8119247/" target='_blank' rel='noreferrer'>
            <BsLinkedin />
          </a>
        </div>
        <div>
          <a href="https://www.instagram.com/gabetierra_tech/" target='_blank' rel='noferrer'>
            <BsInstagram />
          </a>
        </div>
        <div>
          <a href="https://www.github.com/GTierra2000" target='_blank' rel='noferrer'>
            <BsGithub />
          </a>
        </div>
    </div>
  )
}

export default SocialMedia
